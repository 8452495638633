import { Action, createReducer, on } from '@ngrx/store';
import { ClientEntity } from '../model/client-entity';
import * as ClientEntityAction from '../actions/client-entity.actions';
export * as ClientEntityAction from '../actions/client-entity.actions';

export const clientEntityFeatureKey = 'clientEntity';

export interface State {
  clientEntity: ClientEntity;
}

export const initialState: State = {
  clientEntity: null
};


export const reducer = createReducer(
  initialState,
  on(ClientEntityAction.LoadClientEntity, (state: State, clientEntity: ClientEntity) => ({...state, clientEntity: clientEntity})),
  on(ClientEntityAction.ClearClientEntity, (state: State) => ({...initialState})),
);

export const getClientEntity = (state: State) => state.clientEntity;

